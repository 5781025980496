import { useContext } from 'react'
import { DeviceContext } from './DeviceContext'
import { PopupDispatchContext, PopupStateContext } from './PopupContext'
import { NavigationDispatchContext, NavigationStateContext } from './NavigationContext'

export const useDevice = () => useContext(DeviceContext)
export const usePopup = () => useContext(PopupStateContext)
export const useSetPopup = () => useContext(PopupDispatchContext)
export const useNavigationState = () => useContext(NavigationStateContext)
export const useSetNavigationState = () => useContext(NavigationDispatchContext)
