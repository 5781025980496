import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { BREAK_POINT } from '../../../constants'
import Theme from '../../../theme'

export const FocusedStyle = css`
    font-size: 12px;
    font-weight: 600;
`

type StyledLabelProps = {
    focus?: boolean
    medium?: boolean
    small?: boolean
    rows?: number
}
const StyledText = css<StyledLabelProps>`
    ${Theme.TextStyles['FORM_LABEL']}
    color: ${Theme.Colors['Dark-Purple']};
    opacity: 1;
    z-index: 12;
    position: absolute;
    left: ${Theme.spacing(4) + 2}px;
    right: ${Theme.spacing(4)}px;
    transition: all 150ms ease-in-out;
    pointer-events: none;
    ${({ focus }) => (focus ? FocusedStyle : '')}

    top: ${({ focus, rows }) => (rows ? (focus ? '.25rem' : '1rem') : `${Theme.spacing(focus ? -6 : 0)}px`)};
    ${({ rows }) => (rows ? '' : 'bottom: 0px;')}
    align-items: center;
    white-space: nowrap;
`
export const StyledLabel = styled.label<StyledLabelProps>`
    ${StyledText}
    display: flex;
`

export const StyledP = styled.p<StyledLabelProps>`
    ${StyledText}
    position: unset;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: fit-content;
    flex-grow: 1;
`

type StyledIconProps = {
    focus: boolean
}
export const StyledIcon = styled.label<StyledIconProps>`
    z-index: 0;
    position: absolute;
    right: ${Theme.spacing(4)}px;
    transition: all 150ms ease-in-out;
    pointer-events: none;
    top: 0px;
    bottom: ${({ focus }) => (focus ? 25 : 0)}px;
    width: ${({ focus }) => (focus ? 20 : 25)}px;
    opacity: ${({ focus }) => (focus ? 0 : 1)};
    display: flex;
    align-items: center;
`

export type StyledInputContainerProps = {
    error?: boolean | ReactNode
    position?: string
    squareLeft?: boolean
    squareRight?: boolean
    squareBottom?: boolean
    squareTop?: boolean
    disabled?: boolean
}
export const StyledContainer = css<StyledInputContainerProps>`
    background-color: ${({ disabled }) => Theme.Colors[disabled ? 'Beige' : 'White']};
    border: 2px solid ${({ error }) => Theme.Colors[error ? 'Error' : 'Beige-2']};
    border-bottom-width: ${({ squareBottom }) => (squareBottom ? 1 : 2)}px;
    border-top-width: ${({ squareTop }) => (squareTop ? 1 : 2)}px;
    border-right-width: ${({ squareRight }) => (squareRight ? 1 : 2)}px;
    border-left-width: ${({ squareLeft }) => (squareLeft ? 1 : 2)}px;
    border-top-left-radius: ${({ squareLeft, squareTop }) => (squareLeft || squareTop ? 0 : 10)}px;
    border-top-right-radius: ${({ squareRight, squareTop }) => (squareRight || squareTop ? 0 : 10)}px;
    border-bottom-left-radius: ${({ squareLeft, squareBottom }) => (squareLeft || squareBottom ? 0 : 10)}px;
    border-bottom-right-radius: ${({ squareRight, squareBottom }) => (squareRight || squareBottom ? 0 : 10)}px;

    outline: none;
    transition: all 200ms linear;
`

export const InputStyles = css`
    ${StyledContainer}
    color: ${Theme.Colors['Dark-Purple']};
    height: 41.25px;
    font-size: 16px;
    padding: 20px ${Theme.spacing(4)}px 0px;
    transition: all 150ms ease-in-out;
    :focus {
        border-color: ${Theme.Colors['Yellow-2']};
    }
`

export const ErrorMessage = styled.div<StyledInputContainerProps>`
    color: ${Theme.Colors.Error};
    ${FocusedStyle}
    opacity: ${({ error }) => (error ? 1 : 0)};
    position: ${({ position }) => (position ? position : 'absolute')};
    right: ${Theme.spacing(4)}px;
    top: ${Theme.spacing(0.75)}px;
    transition: all 150ms ease-in-out;

    @media screen and (max-width: ${BREAK_POINT}px) {
        top: ${Theme.spacing(0.85)}px;
    }
`

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 4px;
    padding-left: 4px;
`
