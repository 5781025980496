import { Image, Card, Heading, Container, Section, Markdown, Text } from 'tusk-ui'
import { CMSImage, CMSSection } from 'content/content.types'
import { cx } from 'tusk-ui/utils'

interface LogoGrid extends CMSSection {
    subheading?: string
    logos: CMSImage[]
    variant?: 'standard' | 'card'
}

export function LogoImgGrid({ items, className }: { items: CMSImage[]; className?: string }) {
    return (
        <div
            className={cx(
                'mx-auto grid grid-cols-8 items-center gap-x-120 gap-y-16 tablet:gap-x-100 mobile:grid-cols-4 mobile:gap-48',
                className
            )}
        >
            {items.map((item, i) => (
                <Image
                    key={i}
                    className={cx('mobile:max-h-50 col-span-2 max-h-100 object-contain', {
                        'col-start-2 mobile:col-start-auto': i === 0,
                        'mobile:col-start-2': i === items.length - 1
                    })}
                    src={item.src}
                    alt={item.alt}
                />
            ))}
        </div>
    )
}
export default function LogoGrid({ logos, variant = 'standard', heading, subheading, ...sectionProps }: LogoGrid) {
    return (
        <>
            {variant === 'card' ? (
                <Section py='sm' {...sectionProps}>
                    <Container>
                        <Card scheme='river'>
                            <div className='mb-64 text-center'>
                                <Heading.H2>
                                    <Markdown.Heading markdown={heading} />
                                </Heading.H2>
                                {subheading && <Text className='pt-24'>{subheading}</Text>}
                            </div>
                            <LogoImgGrid items={logos} className='max-w-[800px]' />
                        </Card>
                    </Container>
                </Section>
            ) : (
                <Section {...sectionProps}>
                    <Container className='space-y-64'>
                        <div className='mb-24 text-center'>
                            <Heading.H2 variant='h1'>
                                <Markdown.Heading markdown={heading} />
                            </Heading.H2>
                            {subheading && <Text className='pt-24'>{subheading}</Text>}
                        </div>
                        <LogoImgGrid items={logos} />
                    </Container>
                </Section>
            )}
        </>
    )
}
