import React, { ReactNode } from 'react'
import { usePageContent, UseContentResponse, useGlobalContent } from '../hooks/useContent'
import PageSeo from './PageSeo'

type PageContentProps = {
    path: string
    children?:
        | ReactNode
        | (({
              data,
              loading,
              error
          }: {
              data
              loading?: UseContentResponse['loading']
              error?: UseContentResponse['error']
          }) => ReactNode)
}

interface WithContentProps {
    content: any
}

type ExtractProps<C extends React.FunctionComponent<any>> = Omit<React.ComponentProps<C>, 'content'>

export const withContent =
    <P extends WithContentProps>(path: string, Component: React.FunctionComponent<P>) =>
    (props: ExtractProps<typeof Component>) => (
        // @ts-ignore
        <PageContent path={path}>{({ data }) => <Component {...props} content={data} />}</PageContent>
    )

export const withGlobalContent =
    <P extends WithContentProps>(
        path: string,
        Component: React.FunctionComponent<P>,
        Skeleton: React.FunctionComponent = () => <></>
    ) =>
    (props: ExtractProps<typeof Component>) => {
        const { content, loading, error } = useGlobalContent(path)
        if (error) return <div>{error.message}</div>
        if (loading) return <Skeleton />
        // @ts-ignore
        return <Component {...props} content={content} />
    }

export default function PageContent(props: PageContentProps) {
    const { children, path } = props
    const { content, loading, error } = usePageContent(path)

    if (error) {
        return <div>{error.message}</div>
    }

    return (
        <>
            {!loading && content?.seo && <PageSeo {...content?.seo} />}
            {typeof children === 'function' ? loading ? <></> : <>{children({ data: content?.data })}</> : children}
        </>
    )
}
