import { CMSIcon, CMSSection } from 'content/content.types'
import { Heading, Text, Paragraph, Markdown } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

export default function SectionHead({ heading, description, eyebrow, icon: IconComponent }: SectionHeaderProps) {
    return (
        <div className={cx('flex flex-col items-center text-center')}>
            {IconComponent && (
                <div className='mb-16'>
                    <IconComponent size='lg' />
                </div>
            )}
            {eyebrow && (
                <Text variant='h7' className='mb-16'>
                    {eyebrow}
                </Text>
            )}
            {heading && (
                <Heading.H2 className='mb-24'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H2>
            )}
            {description && <Paragraph className='mb-24'>{description}</Paragraph>}
        </div>
    )
}

export type SectionHeaderProps = {
    description: string
    icon?: CMSIcon
    eyebrow?: string
} & Pick<CMSSection, 'heading'>
