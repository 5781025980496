import { useEffect } from 'react'
import { Outlet, useLocation, useNavigationType } from 'react-router-dom'
import 'tusk-ui/css'
import { ToastContainer } from 'components/atoms/Toast/Toast'

const useScrollHandler = () => {
    const { pathname, hash, key } = useLocation()
    const navigationType = useNavigationType()

    useEffect(() => {
        const isGoingBackInHistory = navigationType === 'POP'
        if (isGoingBackInHistory || hash) return
        window.scrollTo(0, 0)
    }, [pathname, hash, navigationType])

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const el = document.getElementById(hash.replace('#', ''))
            if (el) {
                // timeout since otherwise the element doesn't scroll to the top nicely
                setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' })
                }, 300)
                observer.disconnect()
            }
        })

        if (hash) {
            const el = document.getElementById(hash.replace('#', ''))
            // if the element is not in the DOM yet, wait for it to be added
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
            } else {
                observer.observe(document.body, {
                    childList: true,
                    subtree: true
                })
                // if the element is not added to the DOM within 5 seconds, stop observing
                setTimeout(() => {
                    observer.disconnect()
                }, 5000)
            }
        }

        return () => {
            observer.disconnect()
        }
    }, [key, hash])
}

export default function AppRoot() {
    useScrollHandler()
    return (
        <>
            <Outlet />
            <ToastContainer />
        </>
    )
}
