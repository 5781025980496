import { Card, Heading, Paragraph } from 'tusk-ui'
import { CMSIcon } from 'content/content.types'
import { cx } from 'tusk-ui/utils'

export default function IconCard({ title, description, icon, cardClassName }: IconCardProps) {
    const IconComponent = icon
    return (
        <Card scheme='snow' className={cx('space-y-16', cardClassName)}>
            <div className='inline-block rounded-full bg-gum-100 p-16'>
                <IconComponent className='text-gum' />
            </div>
            <Heading.H4 variant='h7'>{title}</Heading.H4>
            <Paragraph>{description}</Paragraph>
        </Card>
    )
}

export type IconCardProps = {
    icon: CMSIcon
    title: string
    description: string
    cardClassName?: string
}
