import { Carousel } from 'tusk-ui'
import IconCard, { IconCardProps } from 'mammoth/components/IconCard'

export default function IconCardGrid({ items }: IconCardGridProps) {
    return (
        <>
            <div className='grid grid-cols-3 gap-24 tablet:grid-cols-2 mobile:hidden'>
                {items.map((item, index) => (
                    <IconCard key={index} {...item} />
                ))}
            </div>
            <Carousel.Root className='hidden mobile:block'>
                <Carousel.Content>
                    {items.map((item, index) => (
                        <Carousel.Item key={index}>
                            <IconCard {...item} cardClassName='h-full' />
                        </Carousel.Item>
                    ))}
                </Carousel.Content>
                <Carousel.Indicators />
            </Carousel.Root>
        </>
    )
}

type IconCardGridProps = {
    items: IconCardProps[]
}
