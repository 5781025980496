import { QueryHookOptions, gql, useQuery } from '@apollo/client'
import { useCustomQuery } from '../index'
import { LeaderboardStandings } from '../components/molecules/EmployeeDashboard/PointsSection'
import { PointHistory } from '../pages/Members/PointsOverview/PointHistorySection'
import { EngagementDetailsModel } from '../models/EngagementDetailsModel'
import { EngagementTypes, Reward } from '../types'
import { useMemo } from 'react'
import { numberToLocalString } from '../util'
import { useEntityContent } from 'mammoth/hooks/useContent'

export const POINT_ENTITY_FRAGMENT = gql`
    fragment PointFields on PointEntity {
        id
        attributes {
            Engagement_Type
            Points
            Emoji_Background_Color
            Transaction_Type
        }
    }
`

const GET_CURRENT_POINTS = gql`
    query CurrentPoints {
        currentPoints
    }
`
export const useCurrentPoints = () => {
    const { data, ...rest } = useCustomQuery(GET_CURRENT_POINTS)
    return { data: data?.currentPoints as number, ...rest }
}

export const ORG_LEADERBOARD_STANDINGS_QUERY = gql`
    query OrgLeaderboardStandings($limit: Int, $offset: Int, $monthly: Boolean) {
        orgLeaderboardStandings(limit: $limit, offset: $offset, monthly: $monthly) {
            data {
                userId
                firstName
                lastName
                teamName
                imageUrl
                allTimePoints
                place
            }
            total
            offset
        }
    }
`

type LeaderboardStandingsRes = {
    data: LeaderboardStandings[]
    offset: number
    total: number
}
export const useOrgLeaderboardStandings = (limit?: number, offset?: number, monthly?: boolean) => {
    const { data, ...rest } = useCustomQuery(ORG_LEADERBOARD_STANDINGS_QUERY, { variables: { limit, offset, monthly } })
    return { data: data?.orgLeaderboardStandings as LeaderboardStandingsRes, ...rest }
}

export const POINT_HISTORY_QUERY = gql`
    query PointHistory($limit: Int, $offset: Int, $dateSort: SortDirection) {
        pointHistory(limit: $limit, offset: $offset, dateSort: $dateSort) {
            total
            data {
                actionType
                pointsChange
                dateEarned
                balance
            }
        }
    }
`

type PointHistoryArgs = {
    limit?: number
    offset?: number
    dateSort?: 'asc' | 'desc'
}
type PointHistoryRes = {
    total: number
    data: PointHistory[]
}
export const usePointHistory = ({ limit, offset, dateSort }: PointHistoryArgs) => {
    const { data, ...rest } = useCustomQuery(POINT_HISTORY_QUERY, { variables: { limit, offset, dateSort } })
    return { data: data?.pointHistory as PointHistoryRes, ...rest }
}

const GET_POINTS = gql`
    query points($pagination: PaginationArg) {
        points(pagination: $pagination, publicationState: LIVE) {
            data {
                ...PointFields
            }
        }
    }
    ${POINT_ENTITY_FRAGMENT}
`

export const usePointsWithContent = (pointsData: { attributes: EngagementDetailsModel }[]) => {
    const handles = useMemo(
        () => pointsData?.map((r) => r.attributes.Engagement_Type.replaceAll('_', '-')),
        [pointsData]
    )
    const { data: rewardsData, loading, error } = useEntityContent<EngagementDetailsModel>('points', handles)

    return {
        data: pointsData?.map((item) => ({
            attributes: {
                ...item.attributes,
                ...rewardsData[item.attributes.Engagement_Type.replaceAll('_', '-')]
            }
        })),
        loading,
        error
    }
}

function useAllPointsDetails() {
    const { data, loading, error, ...rest } = useQuery(GET_POINTS, {
        variables: { pagination: { limit: 99, start: 0 } }
    })
    const { data: pointsData, loading: contentLoading, error: contentError } = usePointsWithContent(data?.points?.data)
    return { data: pointsData || [], loading: loading || contentLoading, error: error || contentError, ...rest }
}

export type EngagementDetailsMap = {
    [key: string]: EngagementDetailsModel & {
        Formatted_Points?: string
        Formatted_Points_Short?: string
    }
}
export function useEngagementDetails(): EngagementDetailsMap {
    const { data: allPointsDetails } = useAllPointsDetails()
    return useMemo(
        () =>
            allPointsDetails.reduce((mapped, item) => {
                mapped[item.attributes.Engagement_Type] = {
                    ...item.attributes,
                    Formatted_Points: item.attributes.Points
                        ? `+${numberToLocalString(item.attributes.Points)} points`
                        : undefined,
                    Formatted_Points_Short: item.attributes.Points
                        ? `+${numberToLocalString(item.attributes.Points)} pts`
                        : undefined
                }
                return mapped
            }, {}),
        [allPointsDetails]
    )
}

export const REWARD_VALUES_QUERY = gql`
    query RewardValues {
        rewardValues {
            points
            reward {
                type
                value
            }
        }
    }
`
export const useRewardValues = () => {
    const { data, ...rest } = useCustomQuery(REWARD_VALUES_QUERY)
    return { data: data?.rewardValues as Reward[], ...rest }
}

export const CAN_EARN_POINTS = gql`
    query CanEarnPoints($engagementType: EngagementTypes!) {
        canEarnPoints(engagementType: $engagementType)
    }
`

export const useCanEarnPoints = (engagementType: EngagementTypes, options?: QueryHookOptions) => {
    const { data, ...rest } = useCustomQuery(CAN_EARN_POINTS, {
        ...options,
        variables: { engagementType, ...options?.variables }
    })
    return { data: data?.canEarnPoints as number, ...rest }
}
