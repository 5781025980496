import { useAnalytics } from 'analytics'
import { ownerRoutes, adminRoutes, memberRoutes as cncMemberRoutes } from 'App/routes'
import useUser from 'gql/useUser'
import { Locations } from 'Locations'
import { memberRoutes as mammothMemberRoutes } from 'mammoth/routes'
import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { OnboardingStep } from 'types'
import { isUserOnboarded, isOrgAdmin } from '../../util'

const memberPaths: string[] = [...cncMemberRoutes.map((r) => r.path), ...mammothMemberRoutes.map((r) => r.path)]
const adminPaths: string[] = [...adminRoutes.map((r) => r.path), ...ownerRoutes.map((r) => r.path)]

const getRecentView = () => {
    switch (localStorage.getItem('recentView')) {
        case 'admin':
            return 'admin'
        case 'member':
            return 'member'
        default:
            return
    }
}
const setRecentView = (view: 'admin' | 'member') => localStorage.setItem('recentView', view)

export const useSetRecentView = () => {
    const { pathname } = useLocation()
    useEffect(() => {
        const path = pathname.split('/')[2]
        const recentView = getRecentView()
        if (adminPaths.includes(path) && recentView !== 'admin') setRecentView('admin')
        else if (memberPaths.includes(path) && recentView !== 'member') setRecentView('member')
    }, [pathname])
}

export function useAuthPath() {
    const analytics = useAnalytics()
    const { loading, data: user } = useUser()
    const [params] = useSearchParams()
    const rtUrl = params.get('rtUrl')

    if (user?.id) analytics.alias(user.id)
    let path = ''

    if (user) {
        path = Locations.Dashboard()
        if (isOrgAdmin(user) && getRecentView() !== 'member') path = Locations.OrgDashboard()
        if (rtUrl) path = rtUrl
        if (!isUserOnboarded(user)) path = Locations.Onboarding(OnboardingStep.AccountSettings)
    }

    return { loading, path }
}
