import { CMSImage, CMSSection } from 'content/content.types'
import Autoplay from 'embla-carousel-autoplay'
import { Carousel, Container, Image, Section } from 'tusk-ui'

interface GallerySectionProps extends CMSSection {
    items: CMSImage[]
}

export default function Gallery({ items, ...rest }: GallerySectionProps) {
    return (
        <Section py='none' {...rest}>
            <Container className='mt-64 pr-0 tablet:pr-0 mobile:pr-0'>
                <Carousel.Root
                    plugins={[
                        Autoplay({
                            delay: 3000
                        })
                    ]}
                >
                    <Carousel.Content>
                        {items.map((image, index) => (
                            <Carousel.Item key={index} className='basis-[40%]'>
                                <Image className='rounded-xl' key={index} src={image.src} alt={image.alt} />
                            </Carousel.Item>
                        ))}
                    </Carousel.Content>
                </Carousel.Root>
            </Container>
        </Section>
    )
}
