import { lazy } from 'react'
import IconBase from './IconBase'

export const Base = IconBase

export const AbcBlocks = lazy(() => import('./AbcBlocks'))
export const Airplane = lazy(() => import('./Airplane'))
export const Airwave = lazy(() => import('./Airwave'))
export const ArrowDown = lazy(() => import('./ArrowDown'))
export const ArrowLeft = lazy(() => import('./ArrowLeft'))
export const ArrowRight = lazy(() => import('./ArrowRight'))
export const ArrowUp = lazy(() => import('./ArrowUp'))
export const AwardStar = lazy(() => import('./AwardStar'))
export const BarGraphDown = lazy(() => import('./BarGraphDown'))
export const Brain = lazy(() => import('./Brain'))
export const Celebration = lazy(() => import('./Celebration'))
export const ChevronDown = lazy(() => import('./ChevronDown'))
export const Check = lazy(() => import('./Check'))
export const Checkmark = lazy(() => import('./Checkmark'))
export const Clock = lazy(() => import('./Clock'))
export const Close = lazy(() => import('./Close'))
export const Commute = lazy(() => import('./Commute'))
export const DataLoss = lazy(() => import('./DataLoss'))
export const Devices = lazy(() => import('./Devices'))
export const Diet = lazy(() => import('./Diet'))
export const DotPin = lazy(() => import('./DotPin'))
export const Facebook = lazy(() => import('./Facebook'))
export const Footprint = lazy(() => import('./Footprint'))
export const Globe = lazy(() => import('./Globe'))
export const GraduationHat = lazy(() => import('./GraduationHat'))
export const HeartCheck = lazy(() => import('./HeartCheck'))
export const HeartLine = lazy(() => import('./HeartLine'))
export const Home = lazy(() => import('./Home'))
export const Hotels = lazy(() => import('./Hotels'))
export const InputError = lazy(() => import('./InputError'))
export const InputSuccess = lazy(() => import('./InputSuccess'))
export const Library = lazy(() => import('./Library'))
export const LinkedIn = lazy(() => import('./LinkedIn'))
export const Mail = lazy(() => import('./Mail'))
export const Meals = lazy(() => import('./Meals'))
export const MenuHamburger = lazy(() => import('./MenuHamburger'))
export const Monitoring = lazy(() => import('./Monitoring'))
export const Pause = lazy(() => import('./Pause'))
export const Payments = lazy(() => import('./Payments'))
export const PersonCircle = lazy(() => import('./PersonCircle'))
export const PiggyBank = lazy(() => import('./PiggyBank'))
export const Play = lazy(() => import('./Play'))
export const PlayArrow = lazy(() => import('./PlayArrow'))
export const Plus = lazy(() => import('./Plus'))
export const SearchPerson = lazy(() => import('./SearchPerson'))
export const Shopping = lazy(() => import('./Shopping'))
export const ShoppingCart = lazy(() => import('./ShoppingCart'))
export const Spinner = lazy(() => import('./Spinner'))
export const Sprout = lazy(() => import('./Sprout'))
export const Stretching = lazy(() => import('./Stretching'))
export const Suitcase = lazy(() => import('./Suitcase'))
export const Target = lazy(() => import('./Target'))
export const Travel = lazy(() => import('./Travel'))
export const TriangleDown = lazy(() => import('./TriangleDown'))
export const Trophy = lazy(() => import('./Trophy'))
export const Twitter = lazy(() => import('./Twitter'))
export const Umbrella = lazy(() => import('./Umbrella'))
export const Work = lazy(() => import('./Work'))
