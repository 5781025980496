import { to } from 'to'
import { BACKEND_URL } from './constants'
import { OnboardingStepSlugs } from 'mammoth/pages/Onboarding/util'
import { OnboardingStep } from 'types'

export const Locations = {
    Article: (slug = ':slug') => to('blog', `/${slug}`),
    Blog: () => to('blog'),
    Calculate: (type = ':type') => to('calculate/:type').replace(':type', type),
    OnboardingCalculateEntry: () => to('onboarding-calculate-entry'),
    QuarterlyCalculateEntry: () => to('quarterly-calculate-entry'),
    MigrationCalculateEntry: () => to('migration-calculate-entry'),
    CalculateSubmission: (type = ':type') => to('calculate-submission/:type').replace(':type', type),
    CancelMembership: () => to('cancel-membership'),
    Contact: () => to('contact'),
    Dashboard: () => to('dashboard'),
    AccountSettings: () => to('account-settings'),
    Faq: () => to('faq'),
    ForgotPassword: () => to('forgot-password'),
    Home: () => to(''),
    Login: () => to('login'),
    Logout: () => to('logout'),
    ClimateClass: (tagHandle = '', hash = '') => to('climate-class', tagHandle ? `?tag=${tagHandle}${hash}` : hash),
    ClimateClassView: (handle = ':handle') => to('climate-class-view/:handle').replace(':handle', handle),
    ClimateJourney: () => to('climate-journey'),
    SharedClimateJourney: (token = ':token') => to('share-climate-journey/:token').replace(':token', token),
    Careers: () => to('company'),
    ReserveDemo: () => to('reserve-demo'),
    OrgSignup: (token = ':token') => to('admin-signup/:token').replace(':token', token),
    OrgEditCompany: () => to('admin-edit-company'),
    OrgDashboard: () => to('admin-dashboard'),
    OrgTeamManagement: () => to('admin-team'),
    OrgPayments: () => to('admin-payments'),
    ConnectChatApp: () => to('connect-chat-app'),
    TeamsInstructions: () => to('teams-instructions'),
    Projects: () => to('projects'),
    Receipts: () => to('receipts'),
    SingleProject: (slug = ':slug') => to('project/:slug').replace(':slug', slug),
    Privacy: () => to('privacy'),
    CookiesPolicy: () => to('cookies-policy'),
    ResetPassword: () => to('reset-password'),
    Rewards: () => to('rewards'),
    ShopifyPrivacy: () => to('brand-app-privacy'),
    Terms: () => to('terms'),
    SecurityCommitment: () => to('security-commitment'),
    EmployeeInviteLink: (token = ':token') => to('employee-invite/:token').replace(':token', token),
    Pdf: (url = ':url') => to('pdf/:url').replace(':url', url),
    Receipt: (id = ':id') => to('receipt/:id').replace(':id', id),
    AuthSocialCallback: () => to('auth/social'),
    OrgSustainabilitySurvey: () => to('employee-climate-survey'),
    SlackConnect: () => `${BACKEND_URL}/slack/connect`,
    PointsOverview: () => to('points-overview'),
    HowToEarnPoints: () => to('how-to-earn-points'),
    PointHistory: () => to('point-history'),
    PointRedemptions: () => to('point-redemptions'),
    TeamsConnect: () => `${BACKEND_URL}/teams/connect`,
    Training: () => to('training'),
    Challenges: () => to('challenges'),
    Challenge: (handle = ':handle') => to('challenge/:handle').replace(':handle', handle),
    AdminChallenges: () => to('admin-challenges'),
    AdminChallenge: (id = ':id') => to('admin-challenge/:id').replace(':id', id),
    Product: () => to('product'),
    CustomerStories: () => to('customer-stories'),
    CustomerStory: (url = ':url') => to('customer-stories', `/${url}`),
    SendChatAppMessage: () => to('send-chat-app-message'),
    SlackLandingPage: () => to('slack-landing-page'),
    Feedback: () => to('feedback'),
    Quiz: (id = ':id') => to('quiz/:id').replace(':id', id),
    VerifyEmail: (email = ':email') => to('email-verification/:email').replace(':email', email),
    Onboarding: (step: OnboardingStep | ':step' = ':step') =>
        to('onboarding/:step').replace(':step', OnboardingStepSlugs[step] ?? step)
}
