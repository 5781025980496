import { CMSLink } from 'content/content.types'
import IconCard, { IconCardProps } from 'mammoth/components/IconCard'
import SectionHead, { SectionHeaderProps } from 'mammoth/components/SectionHead'
import { ButtonLink, Carousel, Container, Section } from 'tusk-ui'

export default function IconCardGridSection({
    items,
    cta,
    ...sectionHead
}: { items: IconCardProps[]; cta?: CMSLink } & SectionHeaderProps) {
    return (
        <Section scheme='river' py='md'>
            <Container>
                <SectionHead {...sectionHead} />
                <div className='grid grid-cols-3 gap-24 tablet:grid-cols-2 mobile:hidden'>
                    {items.map((item, index) => (
                        <IconCard key={index} {...item} />
                    ))}
                </div>
                <Carousel.Root className='hidden mobile:block'>
                    <Carousel.Content>
                        {items.map((item, index) => (
                            <Carousel.Item key={index}>
                                <IconCard {...item} cardClassName='h-full' />
                            </Carousel.Item>
                        ))}
                    </Carousel.Content>
                    <Carousel.Indicators />
                </Carousel.Root>
                {cta && (
                    <div className='flex justify-center'>
                        <ButtonLink className='mt-32' variant='secondary' to={cta.href}>
                            {cta.text}
                        </ButtonLink>
                    </div>
                )}
            </Container>
        </Section>
    )
}
