import * as P from '../pages'

export const publicRoutes = [
    Object.freeze({
        path: 'share-climate-journey/:token',
        element: <P.ClimateJourney />
    }),
    Object.freeze({
        path: 'admin-signup/:token',
        element: <P.OrgSignup />
    }),
    Object.freeze({
        path: 'teams-instructions',
        element: <P.TeamsInstructions />
    }),
    Object.freeze({
        path: 'employee-invite/:token',
        element: <P.EmployeeInviteLink />
    }),
    Object.freeze({
        path: 'receipts',
        element: <P.Receipts />
    }),
    Object.freeze({
        path: 'projects',
        element: <P.Projects />
    }),
    Object.freeze({
        path: 'project/:slug',
        element: <P.SingleProject />
    }),
    Object.freeze({
        path: 'rewards',
        element: <P.Brands />
    }),
    Object.freeze({
        path: 'pdf/:url',
        element: <P.Pdf />
    }),
    Object.freeze({
        path: 'auth/social',
        element: <P.SocialCallback />
    }),
    Object.freeze({
        path: 'employee-climate-survey',
        element: <P.OrgSustainabilitySurvey />
    })
]

export const memberRoutes = [
    Object.freeze({
        path: 'cancel-membership',
        Component: () => <P.CancelMembership />
    }),
    Object.freeze({
        path: 'dashboard',
        Component: () => <P.Dashboard />
    }),
    Object.freeze({
        path: 'climate-class-view/:handle',
        Component: () => <P.ClimateClassViewPage />
    }),
    Object.freeze({
        path: 'climate-class-view/:handle/:seoString?', // old url
        Component: () => <P.ClimateClassViewPage />
    }),
    Object.freeze({
        path: 'climate-journey',
        Component: () => <P.ClimateJourney />
    }),
    Object.freeze({
        path: 'points-overview',
        Component: () => <P.PointsOverview />
    }),
    Object.freeze({
        path: 'how-to-earn-points',
        Component: () => <P.HowToEarnPointsPage />
    }),
    Object.freeze({
        path: 'point-history',
        Component: () => <P.PointHistoryPage />
    }),
    Object.freeze({
        path: 'point-redemptions',
        Component: () => <P.PointRedemptionsPage />
    }),
    Object.freeze({
        path: 'challenges',
        Component: () => <P.ChallengesPage />
    }),
    Object.freeze({
        path: 'challenge/:handle',
        Component: () => <P.ChallengePage />
    })
]

export const adminRoutes = [
    Object.freeze({
        path: 'admin-team',
        Component: () => <P.OrgTeamManagement />
    }),
    Object.freeze({
        path: 'admin-challenges',
        Component: () => <P.AdminChallengesManagementPage />
    }),
    Object.freeze({
        path: 'admin-challenge/:id',
        Component: () => <P.AdminChallengePage />
    }),
    Object.freeze({
        path: 'admin-dashboard',
        Component: () => <P.OrgDashboard />
    }),
    Object.freeze({
        path: 'admin-edit-company',
        Component: () => <P.OrgAccount />
    }),
    Object.freeze({
        path: 'connect-chat-app',
        Component: () => <P.ConnectChatApp />
    })
]

export const ownerRoutes = [
    Object.freeze({
        path: 'admin-payments',
        Component: () => <P.OrgPayments />
    }),
    Object.freeze({
        path: 'receipt/:id',
        Component: () => <P.Receipt />
    })
]
