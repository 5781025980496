import React, { Suspense } from 'react'
import { createBrowserRouter, RouterProvider, RouteObject, Outlet, Navigate } from 'react-router-dom'
import AppRoot from './AppRoot'
import { publicRoutes, memberRoutes, noNavMemberRoutes } from './routes'
import { redirects } from './redirects'
import Private from './outlets/Private'
import { PERMISSIONS } from '../constants'
import i18next from 'i18next'
import Public from './outlets/Public'

const AppCarbon = React.lazy(() => import('./AppCarbon'))
const NavigationOutlet = React.lazy(() => import('./outlets/Navigation'))

const PrivateOutletComponent = () => <Outlet />
const PrivateNavigationOutletComponent = (p) => <NavigationOutlet {...p} />
const LanguageRedirectComponent = () => <Navigate to={`/${i18next.language}`} />

const routes: RouteObject[] = [
    {
        path: '/:language',
        element: (
            <Suspense fallback={<></>}>
                <AppRoot />
            </Suspense>
        ),
        children: [
            {
                element: (
                    <Public>
                        <NavigationOutlet />
                    </Public>
                ),
                children: publicRoutes
            },
            {
                element: <Private permission={PERMISSIONS.Member} Component={PrivateNavigationOutletComponent} />,
                children: memberRoutes
            },
            {
                element: <Private permission={PERMISSIONS.Member} Component={PrivateOutletComponent} />,
                children: noNavMemberRoutes
            },
            {
                path: '*',
                element: <AppCarbon />
            }
        ]
    },
    ...redirects.map((redirect) => ({
        path: redirect.from,
        element: <Navigate to={redirect.to} replace />
    })),
    {
        path: '*',
        Component: LanguageRedirectComponent
    }
]

export const router = createBrowserRouter(routes)

export default function MammothRouter() {
    return <RouterProvider router={router} />
}
