import NoPermission from 'components/NoPermission'
import { PERMISSIONS } from '../../constants'
import { SpinnerPage } from 'components'
import { Navigate, useLocation } from 'react-router-dom'
import { Locations } from 'Locations'
import { getUserPermission } from '../../util'
import { User } from 'types'
import useUser from 'gql/useUser'
import React from 'react'
import { useAuthenticatedLanguageRedirect } from 'mammoth/hooks/useLanguageRedirect'
import MemberPopups from 'mammoth/components/MemberPopups'
import { useSetRecentView } from 'mammoth/hooks/useSetRecentView'

export default function Private({
    permission,
    Component
}: {
    permission: PERMISSIONS
    Component: React.FunctionComponent<{ user: User }>
}) {
    useAuthenticatedLanguageRedirect()
    useSetRecentView()

    const { pathname, search, hash } = useLocation()
    const { data: user, loading } = useUser()
    if (loading) return <SpinnerPage />
    if (!user) {
        const newQueryString = new URLSearchParams({ rtUrl: pathname + search + hash })
        return (
            <Navigate
                to={{
                    pathname: Locations.Login(),
                    search: newQueryString.toString()
                }}
            />
        )
    }
    if (permission > getUserPermission(user)) return <NoPermission />
    return (
        <>
            <Component user={user} />
            <MemberPopups />
        </>
    )
}
