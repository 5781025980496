import { Language } from 'types'

const LESSON_ID_HANDLE_MAP = {
    '1': 'more-about-cow-burps',
    '2': 'a-win-for-renewables',
    '3': 'some-early-wins',
    '4': 'greener-routes-ahead',
    '5': 'germany-leads-the-charge',
    '6': 'reclaim-the-runway',
    '7': 'public-transit-for-all',
    '8': 'natures-power',
    '9': 'cleaning-our-oceans',
    '10': 'victory-for-nature',
    '11': 'new-york-leads-the-way',
    '12': 'reforestation-revolution',
    '13': 'planet-over-luxury',
    '14': 'made-with-sun',
    '15': 'a-solar-success',
    '16': 'lots-of-solar',
    '17': 'earth-friendly-fast-food',
    '18': 'innovative-materials',
    '19': 'food-for-thought-1',
    '20': 'a-groundbreaking-target',
    '21': 'powering-healthcare',
    '22': 'eus-climate-trade-law',
    '23': 'natures-defense-',
    '24': 'sustainable-solutions',
    '25': 'zesty-energy',
    '26': 'a-rice-revolution',
    '27': 'climate-alchemy-',
    '28': 'farewell-foam',
    '29': 'asias-first-solar-lake',
    '30': 'eu-has-spoken',
    '31': 'a-game-changing-solution',
    '32': 'from-graveyard-to-forest',
    '33': 'community-climate-conversations',
    '34': 'a-glass-revolution',
    '35': 'sustainable-tableware',
    '36': 'delicious-and-eco-friendly',
    '37': 'a-symbiotic-relationship',
    '38': 'ai-powered-sorting',
    '39': 'renewables-dominate',
    '40': 'a-first-of-its-kind',
    '41': 'supercharger-partnership',
    '42': 'greenlands-rock-flour',
    '43': 'spidey-cleans-osogbo',
    '44': 'taking-a-stand',
    '45': 'wheel-clean-solution',
    '46': 'bikeable-paris',
    '47': 'tree-mapping',
    '48': 'dynamic-charging-for-evs',
    '49': 'quebecs-progressive-move',
    '50': 'what-is-climate-change',
    '51': 'clearing-the-confusion',
    '52': 'lets-clear-the-air',
    '53': 'tipping-the-balance',
    '54': 'the-bigger-picture',
    '55': 'rising-seas',
    '56': 'the-carbon-culprits',
    '57': 'emission-hotspots',
    '58': 'feeling-the-heat-3',
    '59': 'earths-turbo-thaw',
    '60': 'a-balancing-act',
    '61': 'transportation-emissions',
    '62': 'rooted-reality',
    '63': 'carbon-capture-storage',
    '64': 'harnessing-soil-power',
    '65': 'food-waste-saving-opportunity',
    '66': 'efficiency-matters',
    '67': 'take-a-walk',
    '68': 'hot-water-runs-turbines',
    '69': 'plan-et-parenthood',
    '70': 'leak-no-stone-unturned',
    '71': 'let-them-eat-plants',
    '72': 'sunny-side-up',
    '73': 'from-rotten-to-rooted',
    '74': 'transit-ive-solutions',
    '75': 'regreening-the-tropics',
    '76': 'cooling-the-planet',
    '77': 'branching-out',
    '78': 'lightening-up-with-leds',
    '79': 'windy-clean-energy',
    '80': 'drone-ing-reforestation',
    '81': 'feeling-the-heat-1',
    '82': 'inspiring-efforts',
    '83': 'reef-freshing-innovation',
    '84': 'a-recipe-for-hurricanes',
    '85': 'seas-the-day',
    '86': 'lets-talk-about-topsoil',
    '87': 'unfossil-fuel-future',
    '88': 'powering-a-greener-world',
    '89': 'thawing-permafrost',
    '90': 'step-lightly',
    '91': 'footprint-disparities',
    '92': 'climate-exodus',
    '93': 'a-tree-mendous-solution',
    '94': 'bamboozling-carbon',
    '95': 'hope-for-the-future',
    '96': 'small-but-mighty',
    '97': 'feeling-the-heat-2',
    '98': 'what-is-sustainability',
    '99': 'line-dry',
    '100': 'udderly-sustainabl',
    '101': 'smart-savings',
    '102': 'cooler-wash',
    '103': 'fabric-for-the-future',
    '104': 'look-for-the-star',
    '105': 'shady-business',
    '106': 'fur-the-the-environment',
    '107': 'plant-power-1',
    '108': 'join-the-ride',
    '109': 'plane-and-simple',
    '110': 'pedal-power',
    '111': 'concentrated-efforts',
    '112': 'phantom-energy',
    '113': 'eco-nomical-travel',
    '114': 'leave-only-footprints',
    '115': 'streamlining-meetings',
    '116': 'second-hand-style',
    '117': 'food-for-thought-3',
    '118': 'shower-in-savings',
    '119': 'organics-matter',
    '120': 'eating-local',
    '121': 'route-for-the-planet',
    '122': 'take-an-eco-lift',
    '123': 'less-stops',
    '125': 'get-inducted',
    '126': 'proper-insulation',
    '127': 'dont-idle-about-idling',
    '128': 'reusables-revolution',
    '129': 'less-paper-more-planet',
    '130': 'gifts-for-a-happy-planet',
    '131': 'sustainable-laptops',
    '132': 'greening-your-workspace',
    '133': 'recharge-your-habits',
    '134': 'brew-tifully-sustainable',
    '135': 'put-it-on-autopilot',
    '137': 'bulk-is-the-way-to-go',
    '138': 'reduce-your-foodprint',
    '139': 'trash-talk',
    '140': 'from-trash-to-treasure',
    '141': 'cutting-cards',
    '142': 'wheel-y-great-ideas',
    '143': 'smart-power-strips',
    '144': 'less-paper-more-trees',
    '145': 'retreat-responsibly',
    '146': 'creating-climate-culture',
    '147': 'mindful-data-storage',
    '148': 'where-you-stand',
    '149': 'your-energy-insights',
    '150': 'pawprint-insights',
    '151': 'your-homes-insights',
    '152': 'you-are-what-you-eat',
    '153': 'air-travel-insights',
    '154': 'your-travel-footprint-1',
    '156': 'adventure-insights',
    '157': 'your-travel-footprint-2',
    '158': 'shopping-insights',
    '159': 'furniture-footprint',
    '160': 'your-home-purchases',
    '161': 'how-you-shop',
    '162': 'your-wfh-insights',
    '163': 'commuting-footprint',
    '164': 'your-flying-footprint',
    '165': 'ride-share-insights',
    '166': 'travel-insights',
    '167': 'your-food-footprint',
    '168': 'welcome-direct-msg',
    '169': 'welcome-group-msg',
    '170': 'frequency-reminder',
    '171': 'carbon-cleanse',
    '172': 'food-waste-prevention',
    '173': 'the-root-of-carbon-removal',
    '174': 'clean-indoor-air',
    '175': 'repair-in-a-few-clicks',
    '176': 'burgers-of-the-future',
    '177': 'the-new-era-of-bathrooms',
    '178': 'sustainable-banking',
    '179': 'edible-packaging',
    '180': 'going-beyond-the-shovel',
    '181': 'unconventional-farming',
    '182': 'greener-concrete',
    '183': 'refill-at-your-doorstep',
    '184': 'soil-sequestration',
    '185': 'trash-to-treasure',
    '186': 'ai-powered-optimization',
    '187': 'green-construction',
    '188': 'an-endless-energy-source',
    '189': 'ocean-cleanup-solutions',
    '190': 'rapid-solar-adoption',
    '191': 'our-global-food-system',
    '192': 'breaking-it-down',
    '193': 'plant-power-2',
    '194': 'the-impact-of-protein',
    '195': 'the-case-for-local',
    '196': 'the-impact-of-palm-oil',
    '197': 'safeguarding-seafood',
    '198': 'food-for-thought-2',
    '199': 'a-bittersweet-crop',
    '201': 'teams-welcome',
    '202': 'teams-not-registered',
    '203': 'leaderboard-update',
    '204': 'employee-feedback',
    '205': 'admin-feedback',
    '206': 'point-update',
    '207': 'admin-signup-metrics',
    '208': 'employee-invite',
    '209': 'quarterly-calculation-group-msg',
    '210': 'quarterly-calculation-direct-msg',
    '211': 'calculate-footprint-reminder',
    '212': 'announce-eating-innovations',
    '213': 'announce-points',
    '214': 'more-about-dels-food-waste',
    '215': 'more-about-dels-recycling',
    '216': 'more-about-dels-waste-management',
    '217': 'more-about-dels-energy-usage',
    '218': 'teams-signup-reminder',
    '219': 'capital-ones-impact-strategy',
    '220': 'scopes-1',
    '221': '2030-climate-goals-at-capital-one',
    '222': 'our-2030-goals-explained-reducing-scope-1',
    '223': 'capital-one-1',
    '224': 'our-2030-goals-explained-reducing-scope-3',
    '225': 'ever-heard-about-capital-ones-green-auto-hub',
    '226': 'capital-one-2',
    '227': 'challenge-reminder-single',
    '228': 'challenge-reminder-multi',
    '230': 'announce-name',
    '231': 'announce-challenges',
    '232': 'greenlys-footprint',
    '233': 'greenlys-scope-3',
    '234': 'greenlys-scope-3-digital-emissions-category-is-major',
    '235': 'purchased-services-made-up-the-2nd-biggest-element-of-our-2022-footprint',
    '236': 'the-way-we-travel-as-a-team-has-a-major-impact',
    '247': 'outside-2022-1',
    '248': 'outside-2022-2',
    '249': 'outside-2022-3',
    '250': 'outside-2022-4',
    '251': 'outside-2022-5',
    '252': 'outside-2022-6',
    '253': 'outside-2023-1',
    '254': 'outside-2023-2',
    '255': 'outside-2023-3',
    '256': 'outside-2023-4',
    '257': 'outside-2023-5',
    '258': 'outside-2023-6',
    '259': 'outside-2023-7',
    '260': 'monday-2022-1',
    '261': 'monday-2022-2',
    '262': 'monday-2022-3',
    '263': 'monday-2022-4',
    '264': 'monday-2022-5',
    '265': 'monday-2022-6',
    '266': 'monday-2022-7',
    '267': 'monday-2022-8',
    '268': 'understanding-benevas-climate-targets',
    '269': 'our-net-zero-emissions-strategy',
    '270': 'benevas-2023-carbon-footprint-progress',
    '271': 'potential-challenges-and-limitations',
    '272': 'key-area-sustainable-investment',
    '273': 'benevas-climate-influence-on-peers',
    '274': 'key-area-sustainable-operations',
    '275': 'responsible-materials-management',
    '276': 'key-area-sustainable-insurance',
    '277': 'key-area-community-commitment',
    '278': 'climate-risk-identification-assessment-management',
    '279': 'announce-survey',
    '280': 'ubcs-carbon-footprint',
    '281': 'ubc-climate-action-plan',
    '282': 'ubc-climate-goals',
    '283': 'our-action-roadmap',
    '284': 'focus-sustainable-commuting1',
    '285': 'focus-sustainable-commuting2',
    '286': 'focus-business-air-travel1',
    '287': 'focus-business-air-travel2',
    '288': 'focus-food-systems1',
    '289': 'focus-food-systems2',
    '290': 'focus-zero-waste1',
    '291': 'focus-zero-waste2',
    '292': 'focus-engagement1',
    '293': 'focus-engagement2',
    '294': 'focus-campus-operations1',
    '295': 'focus-campus-operations2',
    '296': 'our-progress',
    '297': 'nudge-outstanding-challenge',
    '298': 'nudge-in-progress-challenge',
    '299': 'alacritys-sdg-commitments',
    '300': 'cleantech-programs',
    '301': 'plastics-action-fund',
    '302': 'esg-training-program',
    '303': 'operational-impact',
    '304': 'nudge-redeem-points-card',
    '305': 'nudge-redeem-points-removals',
    '306': 'announce-quizzes',
    '307': 'announce-quizzes-fr',
    '308': 'chat-app-content',
    '309': 'calculate-solar-capacity',
    '310': 'sign-a-petition',
    '311': 'improve-laundry-routine',
    '312': 'attend-climate-event',
    '313': 'improve-coffee-routine',
    '314': 'use-ecosia-search',
    '315': 'shop-second-hand',
    '316': 'bike-to-work',
    '317': 'eat-plant-based-at-work',
    '318': 'reduce-food-waste-emissions',
    '319': 'round-up-june-18',
    '320': 'resume-climatique-1',
    '321': 'round-up-jun-21',
    '322': 'new-challenge-announcement-1',
    '323': 'annonce-des-nouveaux-defis',
    '324': 'round-up-jun-28',
    '325': 'round-up-jun-28-fr',
    '326': 'round-up-july-5',
    '327': 'round-up-july-5-fr',
    '328': 'two-minute-survey',
    '329': 'chat-app-content-1',
    '330': 'chat-app-content-2',
    '331': 'round-up-july-12',
    '332': 'personalization-announcement',
    '333': 'single-use-plastic-announcement',
    '334': 'round-up-july-19',
    '335': 'round-up-july-26',
    '336': 'round-up-aug-2',
    '337': 'congestion-pricing-challenge',
    '338': 'beneva-wildfire-challenge',
    '339': 'congestion-pricing-reminder-1',
    '340': 'round-up-aug-9',
    '341': 'what-is-manulifes-impact-agenda',
    '342': 'manulife-impact-agenda-pillar-1',
    '343': 'manulife-impact-agenda-pillar-2',
    '344': 'manulife-impact-agenda-pillar-3',
    '345': 'our-journey-to-net-zero-overview',
    '346': 'our-journey-to-net-zero-pillar-1',
    '347': 'our-journey-to-net-zero-pillar-2',
    '348': 'our-journey-to-net-zero-pillar-3',
    '349': 'implementation-plan-pillars',
    '350': 'implementation-plan-operations',
    '351': 'implementation-plan-operational-assets',
    '352': 'implementation-plan-operational-decarbonization-plan',
    '353': 'implementation-plan-general-investments',
    '354': 'implementation-plan-investment-influences',
    '355': 'implementation-plan-investment-decarbonization-plan',
    '356': 'implementation-plan-investment-implementation-strategy',
    '357': 'implementation-plan-products-and-services',
    '358': 'implementation-plan-understanding-our-products-and-services',
    '359': 'implementation-plan-going-beyond-emissions-reductions',
    '360': 'round-up-aug-16',
    '361': 'round-up-aug-23',
    '362': 'chat-app-content-3'
}

const lessonRedirects: { from: string; to: string }[] = Object.entries(LESSON_ID_HANDLE_MAP)
    .map(([id, handle]) =>
        Object.values(Language).map((lang) => ({
            from: `/${lang}/climate-class-view/${id}`,
            to: `/${lang}/climate-class-view/${handle}`
        }))
    )
    .flat()

const CHALLENGE_ID_HANDLE_MAP = {
    '1': 'home-solar-capacity-and-potential-savings-calculator',
    '2': 'support-a-climate-petition',
    '3': 'reduce-carbon-footprint-laundry-routine',
    '4': 'attend-a-climate-event-in-your-city-or-town',
    '5': 'reduce-carbon-footprint-coffee-routine',
    '6': 'switch-your-default-search-engine-to-ecosia',
    '7': 'beni-chrome-shop-second-hand-frequently',
    '8': 'reduce-commute-emissions-by-biking-or-walking-to-work',
    '9': 'next-work-meal-plant-based',
    '10': 'help-prevent-food-waste-with-flashfood-or-too-good-to-go',
    '11': 'clean-up-your-neighborhood-earth-day-any-day',
    '12': 'ride-share-green-mode',
    '13': 'power-your-home-with-renewable-electricity-via-cleanchoice-energy',
    '14': 'ubc-bike-to-work-week-2022',
    '15': 'use-modo-evo-car-share-vehicle-to-get-to-campus',
    '16': 'pick-up-a-colleague-along-your-commute-to-campus-and-get-paid',
    '17': 'ride-a-mobi-bike-share-instead-of-driving',
    '20': 'join-one-of-the-ubc-zero-waste-committees',
    '21': 'connect-with-ubc-sustainability-on-social-media',
    '22': 'buy-your-groceries-from-a-local-farmers-market',
    '24': 'pre-event-switch-your-default-search-engine-to-ecosia',
    '25': 'pre-event-cook-plant-based-meal-dinner',
    '26': 'pre-event-bring-your-own-coffee-mug-water-bottle-to-the-office',
    '27': 'pre-event-help-prevent-food-waste-with-too-good-to-go',
    '28': 'make-your-next-work-meal-plant-based',
    '29': 'reduce-carbon-footprint-coffee-routine-oyw',
    '30': 'add-beni-to-chrome-to-shop-second-hand-more-frequently',
    '31': 'pre-event-when-using-rideshare-services-opt-for-their-green-mode',
    '32': 'pre-event-refer-another-oyw-delegate-to-create-their-mammoth-account',
    '33': 'pre-event-purge-your-inbox',
    '34': 'commuting-into-to-zurich-take-a-train',
    '35': 'day-1-bike-walk-transit-ev-to-circus-knie',
    '36': 'day-1-bike-walk-transit-ev-home-from-circus-knie',
    '37': 'discard-your-lunch-waste-responsibly',
    '38': 'day-2-discard-your-lunch-waste-responsible-way',
    '39': 'day-2-bike-walk-transit-ev-to-circus-knie',
    '40': 'day-2-bike-walk-transit-ev-home-from-circus-knie',
    '41': 'day-2-commuting-home-from-zurich-take-a-train',
    '42': 'post-event-organize-30-min-virtual-garbage-clean-up-colleagues',
    '43': 'post-event-share-mammoth-climates-impact-company-sustainability-leaders',
    '44': 'post-event-join-your-companys-climate-erg',
    '45': 'post-event-bring-reusable-dishware-and-cutlery-to-the-office',
    '46': 'reduce-carbon-footprint-laundry-routine-oyw',
    '47': 'join-bike-to-work-week-team',
    '48': 'support-a-climate-petition-oyw',
    '49': 'reduce-phantom-drain-from-work-devices',
    '51': 'cook-vegetarian-vegan-meal-weekday-lunch',
    '52': 'implement-vegan-vegetarian-options-for-work-subsidized-meals',
    '54': 'compost-your-leftover-food-waste-in-the-office',
    '55': 'take-a-train-on-a-route-where-flights-are-available-on-your-next-work-trip',
    '56': 'purchase-produce-from-an-organic-local-farmers-market',
    '57': 'switch-to-digital-bank-statements',
    '58': 'purge-your-inbox-unsubscribe-from-unneeded-emails-generate-a-slug',
    '59': 'switch-to-recycled-paper-products-at-home',
    '60': 'install-led-lightbulbs-at-home',
    '61': 'dandelion-challenge',
    '62': 'use-bixi-or-avelo-to-move-around-the-city',
    '63': 'coffee-at-work-challenge',
    '64': 'opt-out-from-promotional-mail-sent-to-your-home-mailbox',
    '65': 'composting-challenge',
    '66': 'reduce-home-emissions-by-installing-a-smart-thermostat',
    '67': 'cap-one-purchase-produce-from-an-organic-local-farmers-market',
    '68': 'begin-composting-at-home',
    '69': 'cap-one-install-led-lightbulbs-at-home',
    '70': 'install-a-smart-home-thermostat-to-reduce-energy-usage',
    '71': 'purge-your-inbox-unsubscribe-from-unneeded-emails',
    '72': 'cap-one-switch-to-digital-bank-statements',
    '73': 'unplug-your-work-computer-and-monitor-when-the-work-day-is-done',
    '74': 'use-bike-share-toronto-to-move-around-the-city',
    '75': 'opt-for-a-plant-based-meal-for-lunch-in-the-path',
    '76': 'volunteer-with-a-greener-future',
    '77': 'join-the-canada-green-team',
    '78': 'coffee-at-work-challenge-capital-one',
    '79': 'unplug-your-laptop-to-avoid-phantom-emissions',
    '80': 'reduce-emissions-by-composting-at-home',
    '81': 'reduce-waste-with-ubcs-choose-to-reuse-program',
    '82': 'install-led-lightbulbs-to-reduce-emissions-and-cost',
    '83': 'support-the-break-free-from-plastic-pollution-act',
    '84': 'make-the-switch-to-paperless-bank-statements',
    '85': 'lobby-to-reinstate-congestion-pricing-in-new-york-city',
    '86': 'bring-your-own-mug-or-water-bottle',
    '87': 'plastic-free-july-ditch-plastic-water-bottles-for-a-reusable',
    '88': 'plastic-free-july-write-to-a-local-business-to-reduce-single-use-plastics',
    '89': 'write-to-your-mp-to-stop-fuelling-wildfires-in-canada-cap-one',
    '90': 'write-to-your-mp-to-stop-fuelling-wildfires-in-canada-ubc',
    '91': 'beneva-wildfire-petition',
    '92': 'plant-a-native-pollinator-plant',
    '93': 'petition-the-us-government-to-declare-climate-change-a-national-emergency',
    '94': 'use-bike-share-to-move-around-the-city-global',
    '95': 'compost-your-food-waste-in-the-office-global',
    '96': 'purge-your-inbox-unsubscribe-from-unneeded-emails-global',
    '97': 'take-a-train-on-a-route-where-flights-are-available-on-your-next-work-trip-global',
    '98': 'purchase-produce-from-an-organic-local-farmers-market-global',
    '99': 'calculate-cost-and-emissions-savings-from-heat-pump',
    '100': 'join-manulife-sustainability-hub',
    '101': 'post-story-in-sustainability-central-page',
    '102': 'volunteer-through-impact-hub'
}

const challengeRedirects: { from: string; to: string }[] = Object.entries(CHALLENGE_ID_HANDLE_MAP)
    .map(([id, handle]) =>
        Object.values(Language).map((lang) => ({
            from: `/${lang}/challenge/${id}`,
            to: `/${lang}/challenge/${handle}`
        }))
    )
    .flat()

const ARTICLES_OLD_NEW_MAP = {
    the_truth_behind_climate_doom: 'the-truth-behind-climate-doom'
}

const articleRedirects: { from: string; to: string }[] = Object.entries(ARTICLES_OLD_NEW_MAP)
    .map(([old, newUrl]) =>
        Object.values(Language).map((lang) => ({
            from: `/${lang}/blog/${old}`,
            to: `/${lang}/blog/${newUrl}`
        }))
    )
    .flat()

export const redirects = [...lessonRedirects, ...challengeRedirects, ...articleRedirects]
