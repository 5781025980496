import styled from 'styled-components'
import { BREAK_POINT } from '../../../constants'
import Theme from '../../../theme'
import { PropsWithChildren } from '../../../types'
import { Collapse } from '../../animations'
import Box, { FlexPosition } from '../../core/Layout/Box'

import { Text } from 'tusk-ui'
import { CheckmarkBox } from './Checkbox'
import { StyledContainer } from './common'
import StepperInput from './StepperInput'
import { useAnalytics } from '../../../analytics'

type RadioContainerProps = {
    checked?: boolean
    disabled?: boolean
    error?: boolean
    naked?: boolean
    alignCircle?: FlexPosition
}
const RadioContainer = styled.div<RadioContainerProps>`
    ${({ naked }) => (naked ? '' : StyledContainer)}
    min-height: ${({ naked }) => (naked ? 29 : 56.25)}px;
    ${({ disabled }) => (!disabled ? 'cursor: pointer;' : '')};
    max-width: unset;
    padding-left: ${({ naked }) => (naked ? 0 : 20)}px;
    padding-right: ${({ naked }) => (naked ? 0 : 20)}px;
    display: flex;
    align-items: ${({ alignCircle = 'center' }) => alignCircle};
    flex-direction: row;
    transition: all 250ms ease-in-out;

    @media screen and (max-width: ${BREAK_POINT}px) {
        /* width: unset; */
    }

    @media screen and (min-width: ${BREAK_POINT}px) {
        :hover {
            background-color: ${({ naked }) => (naked ? 'transparent' : Theme.Colors['Grey-Blue'])};
        }
    }
`

const RadioCircle = styled.span<RadioContainerProps>`
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border: ${({ checked, disabled }) =>
        `${checked ? 8 : 2}px solid ${disabled ? Theme.Colors.Grey400 : Theme.Colors['Dark-Purple']}`};
    border-radius: 50%;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: ${Theme.Colors.White};
`

interface RadioCoreProps extends React.HTMLAttributes<HTMLDivElement> {
    checked: boolean
    naked?: boolean
    alignCircle?: FlexPosition
    disabled?: boolean
}
export const RadioCore = ({
    checked,
    children,
    disabled,
    alignCircle,
    ...other
}: RadioCoreProps & PropsWithChildren) => (
    <RadioContainer alignCircle={alignCircle} checked={!!checked} {...other}>
        <RadioCircle checked={checked} disabled={disabled} />
        {children}
    </RadioContainer>
)

interface RadioProps extends RadioCoreProps {
    label: string
}
const RadioUI = ({ checked, label, disabled, children, onClick, ...other }: RadioProps) => (
    <RadioContainer checked={!!checked} onClick={(e) => !disabled && onClick?.(e)} disabled={disabled} {...other}>
        <RadioCircle checked={checked} disabled={disabled} />
        <Text variant={other.naked ? 'b4' : 'b3'} style={{ lineHeight: 1.1 }}>
            {label}
        </Text>
        {children}
    </RadioContainer>
)

const Radio = ({ onClick, ...other }: RadioProps) => {
    const analytics = useAnalytics()
    return (
        <RadioUI
            onClick={(e) => {
                analytics.track('Click', { Type: 'Radio', Label: other.label || '' })
                onClick?.(e)
            }}
            {...other}
        />
    )
}

export const CheckInput = ({ checked, label, ...other }: RadioProps) => (
    <RadioContainer checked={!!checked} {...other}>
        <CheckmarkBox checked={checked} />
        <Box p={0.75} />
        <Text variant={other.naked ? 'b3' : 'b3'} style={{ lineHeight: 1.1 }}>
            {label}
        </Text>
    </RadioContainer>
)

interface RadioStepperProps extends RadioProps {
    setValue: (val: number) => void
    value: number
}
export const RadioStepper = ({ checked, value, setValue, ...other }: RadioStepperProps) => (
    <Radio checked={checked} {...other}>
        <Collapse open={checked}>
            <Box pt={4} pb={4}>
                <StepperInput width={84} value={value} setValue={setValue} />
            </Box>
        </Collapse>
    </Radio>
)

export default Radio
