import mixpanel, { Dict, Callback } from 'mixpanel-browser'
import { DEV_MODE } from './constants'
import ReactGA from 'react-ga4'
import { PopupTypes } from './components/atoms/Modal'
import { Filters } from './components/organisms/Brands/BrandsSideBar'

if (!DEV_MODE) {
    mixpanel.init('447045c7df17221237cb2fbac73e06af')
    ReactGA.initialize('G-MF5B975B5V')
}

type Empty = Record<string, never>
type EventsWithProporties = {
    'Page Visit': Empty
    'CTA Click': {
        Label: string
    }
    'Show Banner': {
        'Banner Message': string
    }
    'Close Banner': {
        'Banner Message': string
    }
    'Open Filters': Empty
    'Close Filters': Empty
    'Apply Filters': Filters
    'Clear Filters': Empty
    'Click Brand': {
        'Brand Name': string
    }
    Search: {
        'Search Term': string
    }
    'Show Tooltip': {
        Trigger: 'Brand Card' | 'Brands Hero'
    }
    'Membership Canceled': {
        'Cancel Reason': string
    }
    'Completed Field': {
        'Field Name': string
    }
    'Enter Field': {
        'Field Name': string
    }
    'Form Error': {
        Error: string
    }
    'Form Submitted': {
        Name:
            | 'B2b Lead Gen'
            | 'Reserve Demo'
            | 'Subscribe to Mail List'
            | 'Giveaway'
            | 'Reset Password'
            | 'Forgot Password'
    }
    Click: {
        Label: string
        Type: 'Button' | 'Link' | 'Input Field' | 'Dropdown' | 'Checkbox' | 'Radio' | 'Slider' | 'External Link'
    }
    'Show Popup': {
        Name: PopupTypes
    }
    'Close Popup': {
        Name: PopupTypes
    }
}

export function useAnalytics() {
    return {
        track<K extends keyof EventsWithProporties>(event: K, proporties: EventsWithProporties[K]) {
            if (DEV_MODE) {
                console.log(
                    `[ANALYTICS - TRACK]: \n    event: ${event}${
                        proporties ? `,\n    proporties?: ${JSON.stringify(proporties)}` : ''
                    }`
                )
                return
            }
            mixpanel.track(event, proporties)
        },
        alias(alias: string, original?: string) {
            if (DEV_MODE)
                console.log(
                    `[ANALYTICS - ALIAS]: \n    alias: ${alias}${original ? `,\n    original?: ${original}` : ''}`
                )
            else mixpanel.alias(alias, original)
        },
        identify(unique_id?: string) {
            if (DEV_MODE) {
                console.log(`[ANALYTICS - IDENTIFY]${unique_id ? `: \n    unique_id: ${unique_id}` : ''}`)
                return
            }
            ReactGA.set({ userId: unique_id })
            mixpanel.identify(unique_id)
        },
        set(prop: Dict, callback?: Callback) {
            if (DEV_MODE) {
                console.log(`[ANALYTICS - PEOPLE SET]: \n    prop: ${JSON.stringify(prop)}`)
                return
            }
            ReactGA.set(prop)
            mixpanel.people.set(prop, callback)
        }
    }
}
