import { Section, Container, Heading, Paragraph, Image, Markdown, ButtonLink } from 'tusk-ui'
import { CMSButtonLink, CMSHeading, CMSImage, CMSSection } from 'content/content.types'

interface TrainingHeroProps extends CMSSection {
    body: string
    image: CMSImage
    subHeading?: CMSHeading
    cta?: CMSButtonLink
}

export default function TrainingHero({ heading, subHeading, body, cta, image, ...sectionProps }: TrainingHeroProps) {
    return (
        <Section py='none' {...sectionProps}>
            <Container>
                <div className='flex gap-40 tablet:flex-col mobile:gap-40'>
                    <div className='w-3/5 pt-64 tablet:w-full'>
                        {subHeading && <Heading.H2 className='mb-32 mobile:mb-24'>{subHeading}</Heading.H2>}
                        <Heading.H1 variant='d1' className='mb-32 text-balance mobile:mb-24'>
                            <Markdown.Heading markdown={heading} />
                        </Heading.H1>
                        <Paragraph variant='b1' className='mb-48 text-snow-300 mobile:mb-32'>
                            {body}
                        </Paragraph>
                        {cta && (
                            <ButtonLink variant='primary' className='mb-48 mobile:mb-32' to={cta.href}>
                                {cta.text}
                            </ButtonLink>
                        )}
                    </div>
                    <div className='relative w-2/5 tablet:hidden tablet:h-[500px]'>
                        <Image
                            className='absolute h-full w-[800px] max-w-none rounded-bl-xl object-cover'
                            alt={image.alt}
                            src={image.src}
                        />
                    </div>
                </div>
            </Container>
            <Container className='hidden gap-64 tablet:flex tablet:p-0 mobile:flex-col mobile:p-0'>
                <Image alt={image.alt} src={image.src} />
            </Container>
        </Section>
    )
}
