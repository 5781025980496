import { Section, Container, Heading, Paragraph, JumpLinkCard, Markdown } from 'tusk-ui'
import { CMSSection, CMSJumpLink } from 'content/content.types'

interface BigTextHeroProps extends CMSSection {
    body: string
    anchorLink?: CMSJumpLink
}

export default function BigTextHero({ heading, body, anchorLink, ...sectionProps }: BigTextHeroProps) {
    return (
        <Section py='sm' {...sectionProps}>
            <Container>
                <Heading.H1 variant='d1' className='mb-32 mobile:mb-24'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H1>
                <div className='flex items-center gap-40 mobile:flex-col'>
                    <Paragraph variant='b1' className='text-snow-300'>
                        {body}
                    </Paragraph>
                    {anchorLink && (
                        <JumpLinkCard
                            layout='horizontal'
                            to={anchorLink.href}
                            IconComponent={anchorLink.icon}
                            className='bg-bay-50 px-48 mobile:w-full'
                        >
                            {anchorLink.text}
                        </JumpLinkCard>
                    )}
                </div>
            </Container>
        </Section>
    )
}
