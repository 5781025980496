import { Button } from '../..'
import { Container } from './common'
import { StyledInputField } from './InputField'
import { PropsWithChildren } from '../../../types'
import styled from 'styled-components'

interface StepperButtonProps extends PropsWithChildren {
    onClick: (e: any) => void
    disabled?: boolean
}
const StepperButton = ({ children, onClick, disabled }: StepperButtonProps) => (
    <Button
        style={{
            padding: 0,
            borderRadius: 10,
            minWidth: 44,
            width: 44,
            height: 44,
            borderColor: !disabled ? 'black' : undefined
        }}
        onClick={onClick}
        disabled={disabled}
        variant='secondary'
    >
        {children}
    </Button>
)

const StepperContainer = styled.div`
    display: flex;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`

type TextAlign = 'left' | 'center' | 'right'
type InputProps = {
    width?: number
    textAlign?: TextAlign
}
const Input = styled(StyledInputField)<InputProps>`
    height: 44px;
    width: ${({ width = 42 }) => width}px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
    text-align: ${({ textAlign = 'center' }) => textAlign};
    margin-right: 0.25rem;
    margin-left: 0.25rem;
`

type Props = {
    value: number
    setValue: (val: number) => void
    width?: number
    textAlign?: TextAlign
    strictNumberInput?: boolean
    step?: number
}
function StepperInput({ value, setValue, width, textAlign, strictNumberInput, step = 1 }: Props) {
    return (
        <StepperContainer>
            <StepperButton
                onClick={(e) => {
                    e.stopPropagation()
                    setValue(value - step)
                }}
                disabled={value <= step}
            >
                -
            </StepperButton>
            <Container>
                <Input
                    type='number'
                    value={`${value}`}
                    onChange={({ target }) => setValue(Number(target.value))}
                    onKeyDown={
                        strictNumberInput
                            ? (e) => {
                                  const fakeNumber = ['e', '.', '+', '-'].includes(e.key)
                                  fakeNumber && e.preventDefault()
                              }
                            : undefined
                    }
                    width={width}
                    textAlign={textAlign}
                />
            </Container>
            <StepperButton
                onClick={(e) => {
                    e.stopPropagation()
                    setValue(value + step)
                }}
            >
                +
            </StepperButton>
        </StepperContainer>
    )
}

export default StepperInput
